import "../scss/app.scss";

global.templates = [];

// App
// import "./app/PackSteps/init";
// import "./app/Modal/init";
// import "./app/SearchOptions/init";
import "./app/Slider/init";
import "./app/FormTimerManager/init";
var iframeResize_js = require("./vendor/iframeResizer.contentWindow.min.js");
